<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        sort="invoiceId"
        title="Shanto IT Invoices"
        icon="mdi-equal-box"
        stateendpoint="shantoitInvoice.shantoitInvoices"
        :actions="actions"
        :show-default-action-buttons="false"
        :show-shantoit-selector="true"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";

export default {
  name: 'ShantoITInvoices',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Shanto IT Invoices | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Invoice No',
          value: 'invoiceId'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Payment Status',
          value: 'paymentStatus'
        },
        {
          text: 'Created Date',
          value: 'created_at'
        },
        {
          text: 'Payment Date',
          value: 'paymentDate'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'right'
        }
      ],
      columns: [
        {value: 'invoiceId'},
        {value: 'paymentDate'},
        {value: 'created_at'}
      ],
      actions: {
        load: 'loadShantoITInvoices'
      }
    }
  }
}
</script>
